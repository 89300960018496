import { ActionIcon, Group, Menu, Stack } from '@mantine/core';
import { IconDiamond, IconDotsVertical, IconInfoCircle, IconShare3 } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import React from 'react';
import { z } from 'zod';
import { FeedCard } from '~/components/Cards/FeedCard';
import { useCardStyles } from '~/components/Cards/Cards.styles';
import { EdgeMedia } from '~/components/EdgeMedia/EdgeMedia';
import { AddToCollectionMenuItem } from '~/components/MenuItems/AddToCollectionMenuItem';
import { ReportMenuItem } from '~/components/MenuItems/ReportMenuItem';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { openContext } from '~/providers/CustomModalsProvider';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { ReportEntity } from '~/server/schema/report.schema';
import { aDayAgo } from '~/utils/date-helpers';
import { trpc } from '~/utils/trpc';
import { CollectionType, CosmeticType } from '@prisma/client';
import HoverActionButton from '~/components/Cards/components/HoverActionButton';
import { UseQueryModelReturn } from '~/components/Model/model.utils';
import { env } from '~/env/client.mjs';
import { useAmorTippingStore } from '~/components/Amor/InteractiveTipAmorButton';
import { useModelCardContext } from '~/components/Cards/ModelCardContext';
import { useInView } from '~/hooks/useInView';
import { HolidayFrame } from '../Decorations/HolidayFrame';
import { ShareButton } from '~/components/ShareButton/ShareButton';
import { Reactions } from '~/components/Reaction/Reactions';
import { isValidUrl } from '~/utils/freq';
import { slugit } from '~/utils/string-helpers';

const IMAGE_CARD_WIDTH = 450;
// To validate url query string
const querySchema = z.object({
  model: z.coerce.number().optional(),
  hidden: z.coerce.boolean().optional(),
});

export function ModelCard({ data, forceInView }: Props) {
  const { ref, inView } = useInView({
    rootMargin: '200% 0px',
    skip: forceInView,
    initialInView: forceInView,
  });

  const { classes, cx, theme } = useCardStyles({ aspectRatio: 1 });

  const router = useRouter();
  const currentUser = useCurrentUser();
  const features = useFeatureFlags();
  const queryResult = querySchema.safeParse(router.query);
  const hiddenQuery = queryResult.success ? queryResult.data.hidden : false;
  const modelId = queryResult.success ? queryResult.data.model : undefined;
  const tippedAmount = useAmorTippingStore({ entityType: 'Model', entityId: data.id });

  const { data: { Favorite: favoriteModels = [] } = { Favorite: [] } } =
    trpc.user.getEngagedModels.useQuery(undefined, {
      enabled: !!currentUser,
      cacheTime: Infinity,
      staleTime: Infinity,
    });

  let contextMenuItems: React.ReactNode[] = [];
  contextMenuItems = contextMenuItems.concat([
    <AddToCollectionMenuItem
      key="add-to-collection"
      onClick={() =>
        openContext('addToCollection', { modelId: data.id, type: CollectionType.Model })
      }
    />,
  ]);

  // contextMenuItems = contextMenuItems.concat([
  //   <AddToShowcaseMenuItem key="add-to-showcase" entityType="Model" entityId={data.id} />,
  // ]);

  contextMenuItems = contextMenuItems.concat([
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <ShareButton
        url={router.asPath}
        title={data.name}
        collect={{ modelId: data.id, type: CollectionType.Model }}
      >
        <button
          className={classes.sharebtn}
          color={theme.colorScheme === 'dark' ? 'dark.6' : 'white'}
        >
          <div className={classes.icondiv}>
            <IconShare3 size={14} />
          </div>
          <div>Share</div>
        </button>
      </ShareButton>
    </div>,
  ]);

  contextMenuItems = contextMenuItems.concat([
    <ReportMenuItem
      key="report-image"
      label="Report"
      onReport={() =>
        openContext('report', {
          entityType: ReportEntity.Image,
          // Explicitly cast to number because we know it's not undefined
          entityId: data?.id as number,
        })
      }
    />,
  ]);

  if (currentUser?.isModerator && env.NEXT_PUBLIC_MODEL_LOOKUP_URL) {
    contextMenuItems.unshift(
      <Menu.Item
        component="a"
        key="lookup-model"
        target="_blank"
        icon={<IconInfoCircle size={14} stroke={1.5} />}
        href={`${env.NEXT_PUBLIC_MODEL_LOOKUP_URL}${data.id}`}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          e.stopPropagation();
          window.open(`${env.NEXT_PUBLIC_MODEL_LOOKUP_URL}${data.id}`, '_blank');
        }}
      >
        Lookup Model
      </Menu.Item>,
    );
  }

  const isNew = data.createdAt && data.createdAt > aDayAgo;

  const { useModelVersionRedirect } = useModelCardContext();
  let href = `/models/${data.id}/${slugit(data.name)}`;

  if (useModelVersionRedirect) href += `?modelId=${data.id}`;

  return (
    <HolidayFrame>
      <FeedCard href={href}>
        <div className={classes.root} ref={ref}>
          <div className={classes.content} style={{ opacity: inView ? 1 : undefined }}>
            {inView && (
              <>
                <Group
                  spacing={4}
                  position="right"
                  align="end"
                  className={cx(classes.contentOverlay, classes.top)}
                  noWrap
                >
                  <Stack spacing="xs">
                    {contextMenuItems.length > 0 && (
                      <Menu position="left-start" withArrow offset={-5} withinPortal>
                        <Menu.Target>
                          <ActionIcon
                            variant="transparent"
                            p={0}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <IconDotsVertical
                              size={24}
                              color="#fff"
                              style={{ filter: `drop-shadow(0 0 2px #000)` }}
                            />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          {contextMenuItems.map((el) => el)}
                        </Menu.Dropdown>
                      </Menu>
                    )}

                    <HoverActionButton
                      label="Go"
                      size={30}
                      color="gold"
                      variant="filled"
                      data-activity="create:model-card"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.open(data.deep_link);
                      }}
                    >
                      <IconDiamond stroke={2.5} size={16} />
                    </HoverActionButton>
                  </Stack>
                </Group>
                <EdgeMedia
                  src={JSON.parse(data.image_urls ?? '[]').filter((url: string) => isValidUrl(url))[0]}
                  name=""
                  alt={data.description}
                  width={IMAGE_CARD_WIDTH}
                  placeholder="empty"
                  className={classes.image}
                  loading="lazy"
                  wrapperProps={{ style: { height: '100%', width: '100%' } }}
                  contain
                />
                <Reactions
                  entityId={data?.id}
                  entityType="model"
                  reactions={data.reactions}
                  metrics={{
                    likeCount: data.rank?.likeCountAllTime,
                    dislikeCount: data.rank?.dislikeCountAllTime,
                    heartCount: data.rank?.heartCountAllTime,
                    laughCount: data.rank?.laughCountAllTime,
                    cryCount: data.rank?.cryCountAllTime
                  }}
                  className={classes.reactions}
                />
              </>
            )}
          </div>
        </div>
      </FeedCard>
    </HolidayFrame>
  );
}

type Props = { // @ts-ignore
  data: UseQueryModelReturn[number]; forceInView?: boolean };
