import { Box, Button, createStyles, Grid, Paper, Text } from '@mantine/core';
import { containerQuery } from '~/utils/mantine-css-helpers';
import {
  IconArrowRight,
  IconArticle,
  IconAward,
  IconBoxModel,
  IconCameraSelfie,
  IconCash,
  IconClothesRack,
  IconClubs,
  IconEmpathize,
  IconHeartHandshake,
  IconMoodBoy,
  IconRun,
  IconShare,
  IconWand,
} from '@tabler/icons-react';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useRouter } from 'next/router';

export function HowItWorks() {
  const { cx, classes } = useStyle();
  const currentUser = useCurrentUser();
  const router = useRouter();

  return (
    <>
      <Text
        style={{
          fontFamily: 'cursive',
          fontSize: 35,
          textAlign: 'center',
          marginBottom: 20,
          marginTop: 25,
        }}
      >
        How{' '}
        <Text span c="gold">
          LovedByMen
        </Text>{' '}
        Works for Women?
      </Text>
      <Grid>
        {forWomen.map(({ title, text, icon, buttonText, link }, index) => (
          <Grid.Col span={12} sm={6} md={6} lg={4} xl={3} key={index}>
            <Paper withBorder className={cx(classes.card)} h="100%">
              <div style={{ minHeight: 160 }}>
                <Text className={classes.highlightColor} size="xl" style={{ marginBottom: 10 }}>
                  {title}
                </Text>
                <Text size="lg">{text}</Text>
              </div>
              <Button
                component="a"
                onClick={() => {
                  if (currentUser && link !== '') router.push(link);
                  else if (!currentUser) {
                    router.push('/login');
                  }
                }}
                variant="outline"
                rel="noopener noreferrer"
                rightIcon={<IconArrowRight size={18} />}
                styles={(theme) => ({
                  root: {
                    border: 0,
                    height: 42,
                    padding: 0,
                    marginTop: 10,
                    '&:hover': {
                      backgroundColor: 'inherit',
                      color: 'inherit',
                      boxShadow: 'none',
                    },
                  },
                })}
              >
                {buttonText}
              </Button>
              <div style={{ position: 'absolute', bottom: 0, right: 10, opacity: 0.1, zIndex: 0 }}>
                {icon}
              </div>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>

      <Text
        style={{
          fontFamily: 'cursive',
          fontSize: 35,
          textAlign: 'center',
          marginBottom: 20,
          marginTop: 25,
        }}
      >
        How{' '}
        <Text span c="gold">
          LovedByMen
        </Text>{' '}
        Works for Men?
      </Text>
      <Grid>
        {forMen.map(({ title, text, icon, buttonText, link }, index) => (
          <Grid.Col span={12} sm={6} md={6} lg={4} xl={4} key={index}>
            <Paper withBorder className={cx(classes.card)} h="100%">
              <div style={{ minHeight: 160 }}>
                <Text className={classes.highlightColor} size="xl" style={{ marginBottom: 10 }}>
                  {title}
                </Text>
                <Text size="lg">{text}</Text>
              </div>
              <Button
                component="a"
                onClick={() => {
                  if (currentUser && link !== '') router.push(link);
                  else if (!currentUser) {
                    router.push('/login');
                  }
                }}
                variant="outline"
                rel="noopener noreferrer"
                rightIcon={<IconArrowRight size={18} />}
                styles={(theme) => ({
                  root: {
                    border: 0,
                    height: 42,
                    padding: 0,
                    marginTop: 10,
                    '&:hover': {
                      backgroundColor: 'inherit',
                      color: 'inherit',
                      boxShadow: 'none',
                    },
                  },
                })}
              >
                {buttonText}
              </Button>
              <div style={{ position: 'absolute', bottom: 0, right: 10, opacity: 0.1, zIndex: 0 }}>
                {icon}
              </div>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
}

const useStyle = createStyles((theme) => ({
  card: {
    position: 'relative',
    borderRadius: theme.radius.md,
    padding: theme.spacing.lg,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
  },
  highlightColor: {
    color: theme.colorScheme === 'dark' ? 'white' : 'black',
    zIndex: 10,
  },
}));

const forWomen: {
  title: string;
  text: string;
  icon: React.ReactNode;
  buttonText: string;
  link: string;
}[] = [
  {
    title: 'Post Your Selfies',
    text: 'Share your fashion-forward selfies and videos to showcase your unique style',
    icon: <IconCameraSelfie size={62} />,
    buttonText: 'Post selfies',
    link: '/selfie_posts/create',
  },
  {
    title: 'Express Your Fashion Ideas',
    text: 'Write and publish articles about your fashion inspirations, trends you love, or personal style tips',
    icon: <IconArticle size={62} />,
    buttonText: 'Write an article',
    link: '/articles/create?categoryId=1',
  },
  {
    title: 'Receive Genuine Reactions',
    text: "Get real-time feedback on your selfies from men who appreciate and admire women's fashion",
    icon: <IconMoodBoy size={62} />,
    buttonText: 'Explorer reactions',
    link: '/selfie_posts',
  },
  {
    title: 'Earn and Withdraw Tips',
    text: 'Accumulate tips for your selfies and articles, and convert them into cash through our Selfists Program',
    icon: <IconCash size={62} />,
    buttonText: 'Join selfists program',
    link: '/selfists-program',
  },
  {
    title: 'Create and Manage Your Fashion Club',
    text: 'Start your own fashion club, gather followers, and build a community around your unique style',
    icon: <IconClubs size={62} />,
    buttonText: 'Coming soon',
    link: '',
  },
  {
    title: 'Discover Authentic Fashion Products',
    text: 'Explore and connect with original fashion products and trends that resonate with your style',
    icon: <IconClothesRack size={62} />,
    buttonText: 'Discover fashion products',
    link: '/models',
  },
  {
    title: 'Host a Bounty for Your Perfect Look',
    text: 'Set up a bounty to crowdsource ideas for the ideal fashion style for any occasion',
    icon: <IconRun size={62} />,
    buttonText: 'Create a bounty',
    link: '/bounties/create',
  },
  {
    title: 'Compete in Bounties to Win',
    text: 'Participate in fashion bounties to showcase your creativity and win exciting prizes',
    icon: <IconAward size={62} />,
    buttonText: 'Compete in bounty',
    link: '/bounties',
  },
];

const forMen: {
  title: string;
  text: string;
  icon: React.ReactNode;
  buttonText: string;
  link: string;
}[] = [
  {
    title: "Discover Amazing Women's Fashion",
    text: "Browse through a diverse array of women's fashion, from casual chic to high fashion, and everything in between",
    icon: <IconEmpathize size={62} />,
    buttonText: 'Discover now',
    link: '/models',
  },
  {
    title: 'Provide Valuable Feedback',
    text: "React to women's photo and video selfies, helping them refine and perfect their style",
    icon: <IconHeartHandshake size={62} />,
    buttonText: 'Give key feedback',
    link: '/selfie_posts',
  },
  {
    title: 'Tip to Support Fashionistas',
    text: 'Show your appreciation by tipping the women whose style you admire, encouraging them to keep sharing',
    icon: <IconCash size={62} />,
    buttonText: 'Let’s share tips',
    link: '/selfie_posts',
  },
  {
    title: 'Host a Bounty for Your Dream Look',
    text: "Create a bounty to see the fashion style you've always envisioned brought to life",
    icon: <IconRun size={62} />,
    buttonText: 'Create a bounty',
    link: '/bounties/create',
  },
  {
    title: 'Share Your Fashion Insights',
    text: 'Write and discuss the fashion trends that inspire you or those that don’t, contributing to the community’s collective fashion sense',
    icon: <IconArticle size={62} />,
    buttonText: 'Write an article',
    link: '/articles/create?categoryId=1',
  },
  {
    title: 'Spread Joy Through Fashion',
    text: 'Share the best fashion discoveries with your loved ones, bringing them joy and inspiration',
    icon: <IconShare size={62} />,
    buttonText: 'Share your discoveries',
    link: '/selfie_posts',
  },
];
