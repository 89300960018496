import { Box, Button, Center, Group, Loader, Popover, Text, Title } from '@mantine/core';
import { trpc } from '~/utils/trpc';
import { HomeBlockType, MetricTimeframe } from '@prisma/client';
import { CollectionHomeBlock } from '~/components/HomeBlocks/CollectionHomeBlock';
import { AnnouncementHomeBlock } from '~/components/HomeBlocks/AnnouncementHomeBlock';
import { LeaderboardsHomeBlock } from '~/components/HomeBlocks/LeaderboardsHomeBlock';
import { IconArrowRight, IconInfoCircle } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useInView } from '~/hooks/useInView';
import { ModelsInfinite } from '~/components/Model/Infinite/ModelsInfinite';
import { IsClient } from '~/components/IsClient/IsClient';
import { constants } from '~/server/common/constants';
import { MasonryProvider } from '~/components/MasonryColumns/MasonryProvider';
import { ImageSort, ModelSort } from '~/server/common/enums';
import { MasonryContainer } from '~/components/MasonryColumns/MasonryContainer';
import Link from 'next/link';
import { SocialHomeBlock } from '~/components/HomeBlocks/SocialHomeBlock';
import { Meta } from '~/components/Meta/Meta';
import { env } from '~/env/client.mjs';
import ImagesInfinite from '~/components/Image/Infinite/ImagesInfinite';
import { containerQuery } from '~/utils/mantine-css-helpers';
import { EventHomeBlock } from '~/components/HomeBlocks/EventHomeBlock';
import { Adunit } from '~/components/Ads/AdUnit';
import { adsRegistry } from '~/components/Ads/adsRegistry';
import { isProd } from '~/env/other';
import { Hero } from '~/components/Home/Hero';
import { HowItWorks } from '~/components/Home/HowItWorks';
import { JoinToday } from '~/components/Home/JoinToday';
import { useCurrentUser } from '~/hooks/useCurrentUser';

export default function Home() {
  const { data: homeBlocks = [], isLoading } = trpc.homeBlock.getHomeBlocks.useQuery();

  const [displayModelsInfiniteFeed, setDisplayModelsInfiniteFeed] = useState(false);
  const { ref, inView } = useInView();

  const currentUser = useCurrentUser();

  useEffect(() => {
    if (inView && !displayModelsInfiniteFeed) {
      setDisplayModelsInfiniteFeed(true);
    }
  }, [inView, displayModelsInfiniteFeed, setDisplayModelsInfiniteFeed]);

  return (
    <>
      <Meta
        title="LovedByMen | Style Insights from Men"
        description="Welcome to LovedByMen, a unique fashion social network where men provide valuable feedback to help women refine their style. Men offer sincere insights into women's fashion choices, while women can upload selfies to receive direct evaluations of their outfits. LovedByMen revolutionizes women's fashion by fostering cultural exchange and community interaction within the women's fashion sector."
        links={[{ href: `${env.NEXT_PUBLIC_BASE_URL}/`, rel: 'canonical' }]}
      />
      <MasonryProvider
        columnWidth={constants.cardSizes.model}
        maxColumnCount={7}
        maxSingleColumnWidth={450}
      >
        <MasonryContainer px={0} sx={{ overflow: 'hidden' }}>
          <Adunit mt="md" mb="xs" {...adsRegistry.homePageHeader} />
        </MasonryContainer>

        {isLoading && (
          <Center sx={{ height: 36 }} mt="md">
            <Loader />
          </Center>
        )}

        <MasonryContainer
          py={32}
          sx={(theme) => ({
            marginTop: '7vh',
            [theme.fn.largerThan('lg')]: {
              marginTop: 0,
            },
          })}
          style={{
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(/images/hero-pattern.svg)',
          }}
        >
          <Hero />
        </MasonryContainer>

        <MasonryContainer py={32}>
          <HowItWorks />
        </MasonryContainer>

        <Box
          sx={(theme) => ({
            '& > *:nth-of-type(even)': {
              background:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[8]
                  : theme.fn.darken(theme.colors.gray[0], 0.01),
            },
          })}
        >
          {homeBlocks.map((homeBlock, i) => {
            const showAds = i % 2 === 0 && i > 0;
            switch (homeBlock.type) {
              case HomeBlockType.Collection:
                return (
                  <CollectionHomeBlock
                    key={homeBlock.id}
                    homeBlockId={homeBlock.id}
                    metadata={homeBlock.metadata}
                    showAds={showAds}
                  />
                );
              case HomeBlockType.Announcement:
                return (
                  <AnnouncementHomeBlock
                    key={homeBlock.id}
                    homeBlockId={homeBlock.id}
                    showAds={showAds}
                  />
                );
              case HomeBlockType.Leaderboard:
                return (
                  <LeaderboardsHomeBlock
                    key={homeBlock.id}
                    homeBlockId={homeBlock.id}
                    metadata={homeBlock.metadata}
                    showAds={showAds}
                  />
                );
              case HomeBlockType.Social:
                return (
                  <SocialHomeBlock
                    key={homeBlock.id}
                    metadata={homeBlock.metadata}
                    showAds={showAds}
                  />
                );
              case HomeBlockType.Event:
                return (
                  <EventHomeBlock
                    key={homeBlock.id}
                    metadata={homeBlock.metadata}
                    showAds={showAds}
                  />
                );
            }
          })}

          {!currentUser && (
            <MasonryContainer py={32}>
              <JoinToday />
            </MasonryContainer>
          )}

          {env.NEXT_PUBLIC_UI_HOMEPAGE_IMAGES ? (
            <Box ref={ref}>
              <MasonryContainer py={32}>
                {displayModelsInfiniteFeed && (
                  <IsClient>
                    <Group mb="md" position="apart">
                      <Group>
                        <Title
                          sx={(theme) => ({
                            fontSize: 32,

                            [containerQuery.smallerThan('sm')]: {
                              fontSize: 24,
                            },
                          })}
                        >
                          Selfies
                        </Title>
                        <Popover withArrow width={380}>
                          <Popover.Target>
                            <Box
                              display="inline-block"
                              sx={{ lineHeight: 0.3, cursor: 'pointer' }}
                              color="white"
                            >
                              <IconInfoCircle size={20} />
                            </Box>
                          </Popover.Target>
                          <Popover.Dropdown maw="100%">
                            <Text size="sm" mb="xs">
                              Pre-filtered list of the highest rated images post by the community
                              over the last week
                            </Text>
                          </Popover.Dropdown>
                        </Popover>
                      </Group>

                      <Link href="/selfie_posts" passHref>
                        <Button
                          h={34}
                          component="a"
                          variant="subtle"
                          rightIcon={<IconArrowRight size={16} />}
                        >
                          View All
                        </Button>
                      </Link>
                    </Group>

                    <ImagesInfinite
                      showAds
                      filters={{
                        // Required to override localStorage filters
                        period: MetricTimeframe.Week,
                        sort: ImageSort.Newest,
                        types: undefined,
                        hidden: undefined,
                        followed: false,
                        withMeta: true,
                      }}
                    />
                  </IsClient>
                )}
              </MasonryContainer>
            </Box>
          ) : (
            <Box ref={ref}>
              <MasonryContainer py={32}>
                {displayModelsInfiniteFeed && (
                  <IsClient>
                    <Group mb="md" position="apart">
                      <Group>
                        <Title
                          sx={(theme) => ({
                            fontSize: 32,

                            [containerQuery.smallerThan('sm')]: {
                              fontSize: 24,
                            },
                          })}
                        >
                          Models
                        </Title>
                        <Popover withArrow width={380}>
                          <Popover.Target>
                            <Box
                              display="inline-block"
                              sx={{ lineHeight: 0.3, cursor: 'pointer' }}
                              color="white"
                            >
                              <IconInfoCircle size={20} />
                            </Box>
                          </Popover.Target>
                          <Popover.Dropdown maw="100%">
                            <Text size="sm" mb="xs">
                              Pre-filtered list of models upload by the community that are the
                              highest rated over the last week
                            </Text>
                          </Popover.Dropdown>
                        </Popover>
                      </Group>

                      <Link href="/models" passHref>
                        <Button
                          h={34}
                          component="a"
                          variant="subtle"
                          rightIcon={<IconArrowRight size={16} />}
                        >
                          View All
                        </Button>
                      </Link>
                    </Group>

                    <ModelsInfinite
                      showAds
                      disableStoreFilters
                      filters={{
                        period: isProd ? MetricTimeframe.Week : MetricTimeframe.AllTime,
                        sort: ModelSort.HighestRated,
                        collectionId: undefined,
                      }}
                    />
                  </IsClient>
                )}
              </MasonryContainer>
            </Box>
          )}
        </Box>
      </MasonryProvider>
    </>
  );
}
