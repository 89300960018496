import { Box, Button, createStyles, Text } from '@mantine/core';
import { containerQuery } from '~/utils/mantine-css-helpers';
import { IconArrowRight } from '@tabler/icons-react';
import { FloatingComponents } from '~/components/Home/FloatingComponents';
import { useRouter } from 'next/router';
import { useCurrentUser } from '~/hooks/useCurrentUser';

export function Hero() {
  const { classes } = useStyle();
  const router = useRouter();
  const currentUser = useCurrentUser();

  return (
    <>
      <Box className={classes.kit}>
        <Box className={classes.description}>
          <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Box style={{ lineHeight: 1.5 }}>
              <Text
                sx={{ fontFamily: 'Greycliff CF, sans-serif', lineHeight: 1, marginBottom: 20 }}
                style={{ fontSize: '2.8rem', color: '#f8d26a' }}
                fw={700}
              >
                Unique Fashion Network Revealing Men's Views on Women's Style
              </Text>
              <Text
                size="lg"
                color="dimmed"
                style={{ fontFamily: 'Comic Sans MS, cursive' }}
                italic
              >
                Where Men’s Eyes Meet Women’s Style
              </Text>
            </Box>
            <Button
              component="a"
              onClick={() => {
                if (currentUser) router.push('/selfie_posts');
                else {
                  router.push('/login');
                }
              }}
              rel="noopener noreferrer"
              rightIcon={<IconArrowRight size={18} />}
              styles={(theme) => ({
                root: {
                  border: 0,
                  height: 42,
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 55,

                  '&:hover': {
                    backgroundColor: theme.fn.darken('#8f7630', 0.05),
                  },
                },
              })}
            >
              Get Started
            </Button>
          </div>
        </Box>
        <Box className={classes.media}>
          <FloatingComponents />
        </Box>
      </Box>
    </>
  );
}

const useStyle = createStyles((theme) => ({
  kit: {
    display: 'flex',
  },

  description: {
    display: 'table',
    width: '40%',
  },
  descriptionTitle: {},
  descriptionText: {},
  descriptionButton: {
    marginTop: theme.spacing.md,
  },
  media: {
    marginLeft: '0vw', // Default for widths less than 1339px

    // Apply margin-left of 5vw for widths 1339px and above
    '@media (min-width: 1339px)': {
      marginLeft: '8vw',
    },
    '@media (min-width: 1800px)': {
      marginLeft: '11vw',
    },
    display: 'grid',
    width: '48%',
    gap: theme.spacing.sm,

    img: {
      maxWidth: '100%',
    },
  },
  mediaRow: {
    width: '100%',
    display: 'grid',
    gap: theme.spacing.sm,
    div: {
      height: '7rem',
      borderRadius: theme.radius.sm,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing.sm,

      [containerQuery.largerThan('md')]: {
        height: '10rem',
        padding: theme.spacing.lg,
      },

      svg: {
        width: '100%',
        height: '100%',
        maxHeight: 60,
      },
    },
  },
}));
